export const selectedComponent = componentDetails => {
  return {
    type: 'COMPONENT_SELECTED',
    payload: componentDetails
  }
}

export const getTerminalHistory = () => {
  return {
    type:'GET_TERMINAL_HISTORY',
  }
}

export const addTerminalCommand = terminalCommand => {
  return {
    type:'ADD_TERMINAL_COMMAND',
    payload: terminalCommand
  }
}