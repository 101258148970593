import React, { Component } from 'react';
import avatar from '../assets/avatar_small.png';

export class  About extends Component {
  render() {
    return (
      <div className="ui grid">
      <div className="nine wide left floated column">
        <div className="about">
          <h1 className="ui header" >Hey , <span style={{color:'#EC4E20'}}> I'm Ahmed</span></h1>
          <h4>Full-Stack Developer - I build (Websites|Mobile Apps|Games|Chatbots)</h4>
          <p className="copy-rights">All copyrights reserved @{(new Date()).getFullYear()}</p>
        </div>
      </div>
      <div className="seven wide right floated column">
        <img className="ui centered small image" alt="Ahmed Mohsen" src={avatar}/>
      </div>
    </div>
    )
  }
}

export default About
