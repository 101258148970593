import React, { Component, createRef } from 'react';
import InputTerminal from './input-terminal';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { addTerminalCommand } from '../../actions';
import {focus, touch, blur} from 'redux-form';
import mixpanel from 'mixpanel-browser';
class Terminal extends Component {

  constructor(props){
    super(props);
    this.inputTerminalRef = createRef();
  }

  componentDidMount(){
    console.log(this.inputTerminalRef)
  }
  renderTerminalHistory(){
    const {TerminalCommands} = this.props;
    return (
        TerminalCommands.map( command => {
          return (
            <li style={{listStyle: 'none'}} className="font">mohsen@Write function {command.name}</li>
          )
      })
    )
  }
  // makeInputActive = () => {
  //   const {focus, touch, blur} = this.props;
  //   focus('terminalForm', 'command');
  //   blur('terminalForm', 'command');
  //   focus('terminalForm', 'command');
  //   touch('terminalForm', 'command');
  // }
  redirect(value){
    const {addTerminalCommand, Components , push} = this.props;
    /** 
     * first push the value to terminal history 
     * check if the value exists in component array 
     * if exists return component link
     * else do not do anything 
    */
    addTerminalCommand(value)
    Components.map(component => {
      if(component.name === value || value === component.name.substr(0 , component.name.length - 2)){
        mixpanel.track(component.link);
        return push(component.link);
      }else{
        return '';
      }
    });
  }

  render(){
    return (
      <div style={{margin : '2vh 0'}}>
      <div className="bar">
          <div className="btn btn-red"></div>
          <div className="btn btn-yellow"></div>
          <div className="btn btn-green"></div>
      </div>
      <div className="screen">
            {this.renderTerminalHistory()}
            <InputTerminal onSubmitValue={(value) => {this.redirect(value)}} />
            {/* <div className="screen" onClick={() => this.inputTerminalRef.focus()}> */}
            {/* <input type="text" ref={ (ip) => this.inputTerminalRef = ip} /> */}
            {/* 
            {/* <form onSubmit={()}>
            <label className="font">mohsen@Write function|</label>
            <input type="text" ref={ (ip) => this.inputTerminalRef = ip} />

            </form>
            */} 
      </div> 
     </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    Components: state.component,
    TerminalCommands: state.terminalHistory
  }
}

export default connect(mapStateToProps, {push, addTerminalCommand})(Terminal)