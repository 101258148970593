import forex from '../assets/forex.png';
import goalkeeper from '../assets/goalkeeper.png';
import media from '../assets/media.png';
import gameplay from '../assets/gameplay.png';
import advancedWeb from '../assets/advancedWeb.png';
import chatbots from '../assets/chatbots.png';
import responsiveWebsites from '../assets/responsiveWebsites.png';
import mobileApps from '../assets/mobileApps.png';

import { createConnectedStore } from 'undux'

// Declare your store's initial state.
const initialState = {
  data : {
    overview : {
      color: "#00171F",
      title: "<Overview />",
      description:
        [
          "More than 6 years of experience in software solutions development between full-time jobs and freelance projects.",
          "Graduated from computer science faculty and working as a full-stack developer.",
          "My experience falls around the MERN Stack - NodeJS and Facebook technologies (React & React Native) beside NoSQL databases like MongoDB."
        ],
      hobbies: [
        {
          image: forex,
          title: "Trading in Forex",
        },
        {
          image: goalkeeper,
          title: "Football / Goalkeeper",
        },
        {
          image: media,
          title: "Watching Media/Movies",
        },
        {
          image: gameplay,
          title: "Playing Games",
        },
      ],
    },
    education : {
      color: "#F15152",
      title: "<Education />",
      description:[
        "After 1 year in my faculty , I preferred to go towards the self-study to focus on what I love and what the work market needs more",
         "I took many courses in multiple disciplines , beside the theoritical courses in my college.",
      ],
      certificates:{
          date: "Jul - 2019",
          certificate: "Bachelor in Computer Science",
          college: "Faculty of computers & info",
          school: "AinShams University",
        },
      courses: [
        "Front-end web development",
        "ReactJS",
        "Cross-Platform mobile development React-Native",
        "Backend development - Node/MongoDB",
        "Unity3D Game Development",
        "Artificial intelligence",
        "Business Organization",
        "Object Oriented Programming",
        "Data Structure and Algorithms",
        "Systems Analysis and design",
      ],
    },
    experiences: {
      color: "#1E90FF",
      title: "<Experiences />",
      description:
        [    "My work experience started as a junior developer in a micro company then I went toward the freelancing work",
        "I spent about 18 months working as a freelancer in some popular platforms like Upwork & Mostaql ,",
        "in this period , I worked in different type of projects with clients from multiple countries.",
        "then I went back to work in some local companies , check the below list for my work history."
        ],
      history: [
        {
          date: 'Aug 2022',
          description: 'Malaeb has a mobile application for booking sport venues. My role was developing 2 mobile apps for the customers and another app for the venues owners.',
          employer: 'Malaeb',
          position: 'Mobile Developer (React Native)'
        },
        {
          date: 'Jan 2022',
          description: 'My role was kind of combination between a React Native mobile developer and building automation tools for the mobile build process using Fastlane & NodeJS',
          employer: 'ExpandCart',
          position: 'Software developer (React Native/NodeJS and Fastlane)'
        },
        {
          date: 'Jul 2021',
          description: 'Haader is a delivery mobile app , it has 2 mobile apps , the customer app and the driver app, I built the 2 applications using React Native',
          employer: 'Haader',
          position: 'Mobile Developer (React Native)'
        },
        {
          date: 'Jul 2021',
          description: 'TravelYalla have a web platform which facilitate booking the hotels and trips inside and outside Egypt, My role was building a mobile application for one of their products like Haader using React Native',
          employer: 'TravelYalla',
          position: 'Mobile Developer (React Native)'
        },
        {
          date: 'Apr 2021',
          description: 'CryptoCommand is a mobile app which allows investors to track their crypto markets and manage multiple exchanges portfolio.  I was responsible for the backend system (NodeJS) and the mobile app (React Native)',
          employer: 'Cryptocommand.app (freelance)',
          position: 'Full Stack Developer (NodeJS and React Native)'
        },
        {
          date: 'Mar 2020',
          description: 'Robosto is a mobile app for grocery delivery , my role was building 2 mobile apps one for the customers and one for the drivers, I built the 2 applications with ReactNative and implemented a small part as a full-stack (mobile and backend), for the chat between the customer and the driver using NodeJS/MongoDB and Socket.IO',
          employer: 'Robosto',
          position: 'Mobile Developer (React Native)'
        },
        {
          date: 'Oct 2020',
          description: 'Finztrade is a social trading platform, where the users copy trading actions from a strategy managers.  I was responsible for the backend system (NodeJS) and gain a good experience in MQL 4/5, the main programming language for MetaTrader platform',
          employer: 'FinzTrade (freelance)',
          position: 'Back End Developer (NodeJS)'
        },
        {
          date: 'Mar 2020',
          description: 'BusinessValley incubates multiple projects , one of my roles is to develop the mobile app for one of these projects with React Native',
          employer: 'Business Valley',
          position: 'Mobile developer (React Native)'
        },
        {
          date: 'Mar 2019',
          description: 'My role was developing the backend for the company solution with NodeJS and building the mobile app using React Native.',
          employer: 'Shezlong',
          position: 'Full-Stack Developer (NodeJS and React Native)'
        },
        {
          date: 'Oct 2017',
          description: 'I gained a big experience in this period and provided awesome work to my clients , my rating on these platforms reached to 5 Starts and 100% Job Success Rate after finishing about 20 Job.',
          employer: 'Upwork/Freelancer/Mostaqel',
          position: 'Professional Freelancer (NodeJS/ReactNative)'
        }
      ],
      achievements: {
        color: "#FF9F1C",
        description:[
        "I built a chatbot called “Lancerbot” and It won in the Messenger bots challenge.", 
        "It was a big contest over middle east countries and it was sponsored by Facebook."
        ]
      },
    },
    services : {
      color: "#B8336A",  
      title : "<Services />",
      description : [
        "As a software engineer and provided multiple type of projects to my clients , In the beginning I give my clients a free consultation , like - which platform is better to target ? which technology is better to use ?",
        "My services include providing multiple product types , all related to software development.",
        "popular examples are listed below ,"],
    
      servicesList : [{
          title : "Responsive Websites",
          description : "Professional and responsive website to work in different screen sizes .",
          image : responsiveWebsites
      },
      {
          title : "Mobile Applications",
          description : "Cross-platform mobile application to work on both (Android/IOS)",
          image : mobileApps
      },
      {
          title : "Advanced Web Solutions",
          description : "More advanced web solutions , customized web applications , e-commerce websites , managing web hosting , servers and domains.",
          image : advancedWeb
      },
      {
          title : "Chatbots",
          description : "A cross-platform chatbot works on multiple channels (Messenger , WhatsApp Business , Telegram and so on..",
          image : chatbots
      }]
    },
    workflow: {
      color : "#FF9F1C",
      title : "<Workflow />",
      description : ["The big companies provide a professional and healthy environment with a predetermined plan,",
       "this is how I manage the projects and the team work , a professional plan with detailed steps , steps may be different form a project to another , some steps maybe ignored to fit the client time and budget , but in most cases here’s the most common scenario."
      ],
        steps : [{
        title : "Understanding the project",
        description : "First step is to get the project requirements from the client in form of docs or sometimes , the client share with me a similar application."
      },
      {
        title : "Building a design document",
        description : "After getting the project requirements , I work on converting these requirements into a software product , first I write a design document which include a description and a wireframe for each screen."
      },
      {
        title : "Building UI/UX designs",
        description : "Now the design document is ready with a wireframe and a description for each screen , it’s easy now to build the UI/UX designs based on this design document."
      },
      {
        title : "Making a prototype",
        description : "This is the last step in the design , when we connect our screens and make a prototype where the client can navigate through it and see how the app will work , before actually building it."
      },
      {
        title : "Building the Application",
        description : "After the client approval on the design , It’s the time now , to take the designs we built and convert it to an interactive application."
      },
      {
        title : "Connecting with APIs",
        description : "Now we have working application but technically should be connected with the web services and databases , to achieve the system goals."
      },
      {
        title : "Testing the project",
        description : "It’s the most important step before publishing the project, I write acceptance and unit testing to guarantee the system quality and reduce the possible technical bugs."
      },
      {
        title : "Deploying the project",
        description : "It’s the achievement period - everything is fine now , and the next step is to publish our project to our targeted platforms , in this period , we buy servers and domains or pay fees to publish in stores in case a mobile app and so on."
      }]
    },
    testimonials: {
      color : "#6DD3A2",
      title : "<Testimonials />",
      description : [
        "While I was working in multiple places from a freelancing platforms to local companies" , 
         "I got many feedback from my managers or direct clients here’s some testimonials from mulitple clients. These reviews are public and you can see it in these online platforms."
      ],
      reviews : [{
        platform : "Mostaql",
        comment : "Wonderful programmer , he understand you and implement your needs very well , also suggest ideas to improve your project and available most of the time to reply on all your questions.",
        country : "Qatar"
      },
      {
        platform : "Mostaql",
        comment : "Excellent one to deal with and provide work with quality.",
        country : "KSA"
      },
      {
        platform : "Mostaql",
        comment : "In brief , the best one to deal with.",
        country : "KSA"
      },
      {
        platform : "Upwork",
        comment : "Ahmed is a great person and a great talent!!! He is very knowledgeable and flexible!",
        country : "USA"
      },
      {
        platform : "Upwork",
        comment : "Great quality of work, closely follows guidelines and delivers on time.",
        country : "USA"
      },
      {
        platform : "Upwork",
        comment : "Great to work with",
        country : "Kuwait"
      },
      {
        platform : "Upwork",
        comment : "Very quick and good work, thank you",
        country : "Estonia"
      },
      {
        platform : "Upwork",
        comment : "I had a short work to do and Ahmed did it in two hours communicating with me all the time. I enjoyed working with him. It was very successful and enjoyable",
        country : "Israel"
      },
      {
        platform : "Upwork",
        comment : "Ahmed knows his stuff, he’s fast, and easy to communicate with. Hire him, you won’t regret it.",
        country : "Finland"
      },
      {
        platform : "Upwork",
        comment : "Nice Work.",
        country : "Italy"
      },
      {
        platform : "Upwork",
        comment : "Ahmed , a very hard working guy. We needed some minor changes in our hybrid app built in ionic framework. He was there to fix that on an immediate basis. Though we found little bumps while working, but altogether he has great skills to cross all the hurdles.",
        country : "India"
      },
      {
        platform : "Upwork",
        comment : "He finished the task Promptly and Im looking forward to working with him on other Projects soon",
        country : "Nigeria"
      }]
    },
    pricing: {
      color : "#705036",
      title : "<Pricing />",
      description : [
        "My pricing is highly flexible and negotiable based on the client budget ,",
        "I give a free consulting , whatever you’ll hire or not - contact me and I’ll help you in this very early stage.",
        "the monthly pricing can be also considered like the full-time job salary."
      ],
      prices : [{
        period : "Hourly",
        duration:"HOUR",
        cost : 20
      },
      {
        period : "Daily",
        duration: "DAY",
        cost : 100
      },
      {
        period : "Weekly",
        duration: "WEEK",
        cost : 400
      },
      {
        period : "Monthly",
        duration: "MONTH",
        cost : 1500
      }]
    },
    contact: {
      color : "#006372",
      title : "<Contact />",
      description : [
        "Multiple channels to connect with me", 
        "I’m more active through Messenger & WhatsApp , I recommend them to get quick replies."
      ],
      channels : {
        whatsapp : "https://web.whatsapp.com/send?phone=201023712978",
        messenger : "https://m.me/27mdmo7sn",
        linkedin : "https://www.linkedin.com/in/27mdmo7sn",
        upwork : "https://www.upwork.com/freelancers/~01a29089e86c75aa3b"
      },
      availability: {
        colors: {
            positive : "#6DD3A2",
            negative : "#EC4E20"
        },
        data:[
          { title : "Full-time work" , endDate : {month: 12, year: 2021 } },
          { title : "Part-time work" , endDate : {month: 5, year: 2021 } },
          { title : "Freelance work" , endDate : {month: 5, year: 2021 } }
        ]
      }
    }
  }
};

// Create & export a store with an initial value.
export default createConnectedStore(initialState);