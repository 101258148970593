import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { Provider } from 'react-redux';
import Store from './utils/undux';
import { initializeApp } from "firebase/app";
import { createStore, compose, applyMiddleware } from 'redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import history from './history';
import App from './components/App';
import createRootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import mixpanel from 'mixpanel-browser';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const firebaseConfig = {
  apiKey: "AIzaSyD4FowTJsAwqo3_r1I9tNCqD0hCP4KAnIo",
  projectId: "personalwebsite-c2d0e",
  messagingSenderId: "493933071012",
  appId: "1:493933071012:web:49c5ecd2dd4f7aae8ef431",
  measurementId: "G-HKM527DWH8"
};

initializeApp(firebaseConfig);

mixpanel.init("0b90bbf78dc43bb57b0e812dfccdb552")

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(routerMiddleware(history)))
);
ReactDOM.render( 
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Store.Container>
      <App />
      </Store.Container>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
