import {combineReducers} from 'redux';
import { reducer as reduxForm } from 'redux-form';
import { connectRouter } from 'connected-react-router';

const componentReducers = () => {
  return [
    {name: 'overview()', link:''},
    {name: 'education()', link:'education'},
    {name: 'experiences()', link:'experiences'},
    {name: 'services()', link:'services'},
    {name: 'workflow()', link:'workflow'},
    {name: 'testimonials()',link:'testimonials'},
    {name: 'prices()', link:'prices'},
    {name: 'contact()', link:'contact'},
  ]
}

const terminalHistory = (state = [], action) => {
  switch(action.type){
    case 'ADD_TERMINAL_COMMAND':
      return[
        ...state, {name: action.payload}
      ]
      default: return state 
  }
}

const selectedComponentReducer = (selectedComponent= null, action) => {
  if(action.type === 'COMPONENT_SELECTED'){
    return action.payload
  }
  return selectedComponent;
}
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  component: componentReducers,
  selectedComponent:selectedComponentReducer,
  form: reduxForm,
  terminalHistory
});

export default createRootReducer;