import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import '../styles/App.css';
import { connect } from 'react-redux';
import Navigation from './navigations';
import Terminal from './Terminal/terminal';
import Education from './education';
import Overview from './overview';
import Contact from './contact';
import Experiences from './experiences';
import Services from './services';
import Workflow from './workflow';
import Testimonials from './testimonials';
import Prices from './prices';
import { About } from './about';

class App extends Component {
  state = { mobile: false, width: 0, onscroll: false };

  constructor(props)
  {
    super(props);
  }

  componentWillMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener('resize', this.addOrRemoveClass);
    window.addEventListener('scroll', this.addStickyClass);
  }
  componentDidMount() {
    if (this.state.width < 991) {
      this.setState({ mobile: true })
    }
    else {
      this.setState({ mobile: false })
    }
  }
  
  addStickyClass = () => {
    if (window.scrollY > 20) {
      this.setState({ onscroll: true })
    } else {
      this.setState({ onscroll: false })
    }
  }
  addOrRemoveClass = () => {
    if (window.innerWidth < 991) {
      this.setState({ mobile: true })
    }
    else {
      this.setState({ mobile: false })
    }
  }

  render() {
    return (
      <div className="ui container" style={{ marginTop: '5vh' }}>
        <div className="ui grid stackable">
          <div className="eight wide computer sixteen wide tablet column">
            <div className={!this.state.mobile ? 'ui rail' : ''}>
              <div className={!this.state.mobile ? 'ui sticky terminal-wrapper' : ''}>
                <About style={{ marginBottom: '2vh' }} />
                <Terminal />
                <Navigation />
              </div>
            </div>
          </div>
          <div className="eight wide computer sixteen wide tablet column">
            <Switch>
              <Route exact path="/" component={Overview} />
              <Route exact path="/education" component={Education} />
              <Route exact path="/experiences" component={Experiences} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/workflow" component={Workflow} />
              <Route exact path="/testimonials" component={Testimonials} />
              <Route exact path="/prices" component={Prices} />
              <Route exact path="/contact" component={Contact} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return { selected: state.selectedComponent }
}

export default connect(
  mapStateToProps
)(App);
