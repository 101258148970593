import { getDatabase, ref, onValue} from "firebase/database";

var onValueRef = null;

export function relflectStore(store)
{
    const db = getDatabase();
    const dbRef = ref(db);
    
    if (onValueRef === null)
    {
        onValueRef = onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            store.set('data')(data);
        });
    }
}
