import React from 'react';
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderReviews = (reviews) => {
  return reviews.map(review => {
    return (
      <div className="ui segment testimonial">
        {review.comment}
        <div style={{marginTop:'15px', fontWeight:'bold'}}>
        <span style={{color: '#00171F'}}>@{review.platform}</span>
        <p className="main-color" style={{float:'right', fontSize:'16px'}}>Client From {review.country}</p>
        </div>
      </div>
    )
  })
}

const Testimonials = () => {
  let data = Store.useStore().get('data');
  const {title, color, description, reviews} = data.testimonials;
  relflectStore(Store.useStore());

    return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        {renderDescription(description)}
        <div style={{margin: '8vh 0'}}>
        {renderReviews(reviews)}
        </div>
      </section>
    )
}

export default Testimonials