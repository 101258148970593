import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

class inputTerminal extends Component {

  state = { theInputClicked: false }

  renderInput = ({ input, label, setFieldToBeFocused = () => {} }) => {
    return (
      <div>
        <label className="font">{label}</label>
        <input 
          {...input} 
          autoComplete="off" 
          className="terminal-input" 
          ref={input =>  setFieldToBeFocused(input)} 
          />
      </div>
    );
  };
  onSubmit = formValues => {
    // Send back the the value to terminal component 
    // on which the terminal component take the value and dispatch an action to redux store
    this.props.onSubmitValue(formValues.command);
    // reset or delete input value after submit 
   return this.props.reset();
  };

  render(){
    return (

      <form onSubmit={this.props.handleSubmit(this.onSubmit)} res>
      <Field 
        name="command" 
        component={this.renderInput} 
        label="mohsen@Write function " 
        setFieldToBeFocused={input => {this.input = input;}}
        />
        {/* <button
              type="button"
              className="link-button"
              onClick={() => this.input.focus()}
        >Toggle</button> */}
      </form>
    )
  }
}


export default reduxForm({
  form: 'terminalForm',
})(inputTerminal);