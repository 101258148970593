import React from 'react'
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderServices = (servicesList) => {
  return servicesList.map(service => {
    const divImageStyle ={
       backgroundImage : `url(${service.image})`
    }
    return(
      <div className="sixteen wide mobile eight wide tablet eight wide computer column services-box">
          <h2 className="ui centered header main-color" style={{marginBottom: '0'}}>{service.title}</h2>
            <div className="services-img" style={divImageStyle}>
            </div>
            <p>{service.description}</p>
      </div>
    )
  }); 
}

const Services = () => {
  let data = Store.useStore().get('data');
  const {title, color, description, servicesList} = data.services;
  relflectStore(Store.useStore());

    return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        {renderDescription(description)}
        <div className="ui centered grid" style={{margin: '8vh 0'}}>
          {renderServices(servicesList)}
        </div>
      </section>
    )
}

export default Services
