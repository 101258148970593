import React from 'react';
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

/**
 * Render Hobbies
 */
const renderHobbies = (hobbies) => {
  return hobbies.map(hobby => {
    return (
      <li key={hobby.title} className="ui item four wide computer eight wide mobile column hobby">
        <img alt={hobby.title} src={hobby.image} className="ui centered image" />
        {hobby.title}
      </li>
    )
  });
}

const Overview = () => {
  let data = Store.useStore().get('data');
  const {description, hobbies, title, color} = data.overview;
  relflectStore(Store.useStore());
  
  return (
      <section className="right-section">
      <h1 className="ui header" style={{color}}>{title}</h1>
        {renderDescription(description)}
      <h2 className="ui header"  style={{marginBottom: '40px'}}>Hobbies I love to do in my free time</h2>
      <div className="ui grid">
        {
          renderHobbies(hobbies)
        }
      </div>
      </section>
    )
}

export default Overview;

