import React from 'react'
import renderDescription from './description';
import upworkLogo from '../assets/upwork.svg'
import Cv from '../assets/AMCV.pdf';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() + 
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
 }

 const renderAvalibality = (availability) => {
  return availability.data.map(ava => {
    const date = new Date();
    const expectedMonths = monthDiff(date, new Date(ava.endDate.year, ava.endDate.month));
    if(expectedMonths <= 0){
     return (
      <div key={ava.title} style={{marginBottom: '1rem'}}>
        <h3>{ava.title}</h3>
        <p style={{marginBottom: '0.5rem'}}><i className="check circle icon" style={{color: '#6DD3A2'}}></i> Available </p>
      </div>
     ) 
    }
    return (
      <div key={ava.title} style={{marginBottom: '1rem'}}>
        <h3>{ava.title}</h3>
        <p style={{marginBottom: '0.5rem',color: '#EC4E20'}}><i className="times circle icon" style={{color: '#EC4E20'}}></i>  Not Available (Expected availability after {expectedMonths} months)</p>
      </div>
    )
    
  });
 }


const  Contact = () =>  {
  let data = Store.useStore().get('data');
  const {title, description, color, channels, availability} = data.contact;
  relflectStore(Store.useStore());

  return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        {renderDescription(description)}
        <div className="ui grid">
          <div className="eight wide computer eight wide tablet sixteen wide mobile column contact-links">
            <div className="ui horizontal link list">
            <a className="item" target="_blank" href={channels.whatsapp} ><i className="big whatsapp icon main-color"></i></a>
            <a className="item" target="_blank" href={channels.messenger}><i className="big facebook messenger icon main-color"></i></a>
            <a className="item" target="_blank" href={channels.linkedin} ><i className="big linkedin icon main-color"></i></a>
            <a className="item" target="_blank" href={channels.upwork} style={{paddingTop: '2px'}} ><img className='ui centered image' src={upworkLogo} alt="upwork" style={{top: '5px'}}/></a>
            </div>
          </div>
          <div className="eight wide computer eight wide tablet sixteen wide mobile column cv">
            <button className="right floated cv-button">
              <i className="large file pdf icon"></i>
              <a target="_blank" download="AhmedMohsen-FullStackDeveloper.pdf" href={Cv}>Download CV</a>
            </button>
          </div>
        </div>
        <div className="availability">
        <h2 className="ui header">Availability for hiring</h2>
        {renderAvalibality(availability)}
        </div>
      </section>
  )
}

export default Contact
