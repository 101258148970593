import React from 'react';
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderCourses = (courses,color) => {
  return courses.map(course => {
    return (
      <div className="item">
         <h5 className="ui header"><span style={{color, marginRight:'0.4rem'}}>-></span>{course}</h5>
      </div>
    )
  })
}

/**
 * render certificates
 */
const renderCertificates = (certificates,color) => {
  return(
    <div className="ui grid" style={{marginTop: '4vh', marginBottom: '4vh'}}>
    <div className="three wide right aligned column">
    <i className="huge graduation cap icon" style={{color}}></i>
    <p className="certificate-year">{certificates.date}</p>
    </div>
    <div className="thirteen wide column">
    <div className="collage-school">
      <h4 style={{color: '#00171F'}}>{certificates.college} ({certificates.school})</h4>
      <p style={{color: '#00171F', font: "Italic 14px/20px Verdana"}}>{certificates.certificate}</p>
    </div>
    </div>
  </div>
  )
}
const Education = () => {
  let data = Store.useStore().get('data');
  const {title, description, certificates, courses,color} = data.education;
  relflectStore(Store.useStore());

    return (
      <section className="right-section">
        <h1 className="ui header" style={{color}}>{title}</h1>
        { renderDescription(description) }
        { renderCertificates(certificates,color)}
        <h4>
          in total, i took more than 60 courses in both my college and as self-study, this is a list of the most important courses
        </h4>
        <div className="ui items stackable" style={{listStyle: 'none', paddingInlineStart: '5px'}}>
          {renderCourses(courses,color)}   
        </div>
      </section>
    )
}

export default Education