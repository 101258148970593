import React from 'react';

/**
 * Shared Component between all all page to render description
 * @param {description} description 
 */

const renderDescription = (description) => {
 return description.map(desc => {
   return (
      <h4 style={{fontFamily: 'Roboto, sans-serif', fontWeight:'500'}}>
        {desc}
      </h4>
    )
  });
}

export default renderDescription;