import React from 'react'
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderPrices = (prices) => {
  return prices.map(price => {
    return(
      <div className="five wide computer eight wide tablet column">
        <div className="ui segment price-box">
          <h3 className="ui centered header main-color">{price.period}</h3>
          <hr className="divided" />
          <h4 style={{textAlign: 'center'}}>${price.cost} PER {price.duration}</h4>
        </div>
      </div>
    )
  });
}


const Prices = () => {
  let data = Store.useStore().get('data');
  const {title, color, description, prices } = data.pricing;
  relflectStore(Store.useStore());
  
    return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        {renderDescription(description)}
        <div className="ui grid price-box-selection" style={{marginTop: '8vh'}}>
          {renderPrices(prices)}
          <div className="ten wide computer sixteen wide tablet column">
            <div className="ui segment price-box">
              <h3 className="ui centered header main-color" style={{textAlign:'left'}}>Something else</h3>
              <hr className="divided" />
              <p style={{textAlign:'left'}}>The Project may have a fixed cost or to be divided into milestones.</p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Prices
