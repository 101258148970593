import React from 'react';
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderSteps = (steps) => {
  return steps.map((step,index) => {
    return (
      <>
      <h2 className="ui header text-underline-decoration">
        {/* <div class="middle aligned content" style={{textDecoration: 'underline'}}>
        </div> */}
        <span className="workflow-list">
        <p>{index+1}</p>
        </span>
        {step.title}
      </h2>
      <p className="workflow-desc">{step.description}</p>
    </>
    )
  })
}

const Workflow = () => {
  let data = Store.useStore().get('data');
  const {title, description, steps, color} = data.workflow;
  relflectStore(Store.useStore());

    return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        <p>{renderDescription(description)}</p>
        <div className="ui grid" style={{margin: '7vh 0'}}>
          {renderSteps(steps)}
        </div>
      </section>
    )
}

export default Workflow
