import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTerminalCommand } from '../actions';
import {Link} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

class Navigation extends Component {

  updateTermianlOnClick(command){
    mixpanel.track(command.slice(0, -2))
   return this.props.addTerminalCommand(command)
  }
  renderNavigationList(){
    return  this.props.component.map(component => {
      /** 
       * because of component name end with ()
      */
      const name = component.name.substring(0, component.name.length - 2)
      return(
        <div className="four wide computer four wide tablet eight wide mobile column" key={name} style={{textAlign: 'center', paddingBottom: '0'}}>
          <Link 
            to={`/${component.link}`}
            className={`nav-${name}`}
            onClick={() => this.updateTermianlOnClick(component.name)}
            style={{fontWeight: 'bold'}}
             >
            {component.name}
          </Link>
        </div>  
      )
    })
  }

  render(){
    return <div className="ui relaxed grid navigation" style={{marginTop: 'auto'}}>{this.renderNavigationList()}</div>
  }
}

const mapStateToProps = state => {
  return { component: state.component}
} 

export default connect(
    mapStateToProps,
    {addTerminalCommand}
  )(Navigation)