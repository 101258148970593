import React from 'react'
import renderDescription from './description';
import Store from '../utils/undux';
import { relflectStore } from '../utils/reflectStore';

const renderExperiences = (history) => {
  return history.map(experience => {
    return (
      <>
      <div className="three wide column" style={{textAlign: 'left'}}>
      <i className="huge briefcase icon main-color"></i>
      <p className="text-underline-decoration" style={{fontSize:'16px', color:'#606060', whiteSpace: 'nowrap'}}>{experience.date}</p>
      </div>
      <div className="thirteen wide column">
        <div className="ui grid container">
        <div className="row" style={{paddingBottom:'0'}}>
          <div className="left floated eight wide column">
            <h3 className="main-color">{experience.position}</h3>
          </div>
          <div className="right floated eight wide right aligned column" style={{fontWeight: 'bold'}}>
           @{experience.employer}
          </div>
        </div>
          <div className="sixteen wide column">
            <p className="experience-job-desc text-underline-decoration">
              {experience.description}
            </p>
          </div>
        </div>
      </div>
      </>
    )
  })
}


const Experiences = () => {
  let data = Store.useStore().get('data');
  const {title,description, history, achievements,color} = data.experiences;
  relflectStore(Store.useStore());
  
    return (
      <section className="right-section">
        <h1 style={{color}}>{title}</h1>
        {renderDescription(description)}
        <div style={{margin: '8vh auto'}}>
          <div className="ui grid">
          {renderExperiences(history)}
          </div>
        </div>
        <h2 style={{color: achievements.color}}>Achievements</h2>
        <div className="ui grid">
          <div className="three wide column">
            <i className="huge trophy icon" style={{color: achievements.color}}></i>
          </div>
          <div className="thirteen wide column">
            <div className="text-underline-decoration" style={{color: '#606060'}}>
              {renderDescription(achievements.description)}
            </div>
          </div>
        </div>  
      </section>
    )
}

export default Experiences;
